<template>
  <div style="background: #fff;color: #000;height: 100%;">
    <div style="position: relative;" ref="content" v-if="showDom == false">
      <img v-if="imgs" class="qmimg" :src="imgss" alt />
      <img class="imgs" src="../../assets/img/he1.jpg" alt />
      <img class="imgs" src="../../assets/img/he2.jpg" alt />
      <img class="imgs" src="../../assets/img/he3.jpg" alt />
      <img class="imgs" src="../../assets/img/he4.jpg" alt />

      <img class="imgs" src="../../assets/img/he5.jpg" alt />
      <img v-if="imgs" class="qmimg2" :src="imgss" alt />
    </div>

    <div class="qianming" v-if="showDom == true">
      <div style="border: 2px solid #E6E6E6 ;background-color: white;w-100 h-100">
        <vue-esign
          ref="esign"
          :height="height"
          class="w-100 h-100"
          :isCrop="isCrop"
          :lineWidth="lineWidth"
          :lineColor="lineColor"
          :bgColor.sync="bgColor"
        />
      </div>
      <div
        v-if="showDom"
        class="abs w-100 z-999 p-15"
        style="transform: rotate(90deg);top: 50%;left: -35%;"
      >
        <div class="w-100 m-t-10 f">
          <van-button class="m-5" block type="primary" size="large" @click="handleGenerate()">同意</van-button>
          <van-button class="m-5" block type="default" size="large" @click="handleReset">重新输入</van-button>
        </div>
      </div>
    </div>

    <div class="bott" v-if="showDom == false">
      <van-button class="but" type="primary" @click="qianming">去签名</van-button>
      <van-button class="but" type="danger" @click="zhifu">去支付</van-button>
    </div>
  </div>
</template>

<script>
import orderModel from "../../api/order";
import userModel from "../../api/user";
import utils from "../../assets/utils";
import preventReClick from "../../assets/preventReClick";
import html2canvas from "html2canvas";
export default {
  name: "esignTest",
  components: {},
  data() {
    return {
      list: [],
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      active: "0",
      isWxMini: "",
      //--------------
      imgs: null,
      imgss: null,
      showDom: false,
      baseData: "",
      // --------------
      lineWidth: 11,
      lineColor: "#000000",
      bgColor: "",

      resultImg: "",
      isCrop: false
    };
  },
  methods: {
    qianming() {
      this.showDom = true;
    },
    zhifuzzz() {
      let _this = this;
      html2canvas(this.$refs.content).then(resolve => {
        let imgUrl = resolve.toDataURL("image/png"); //此时就得到了dom元素转成了base64的图片
        var file = _this.dataURLtoFile(imgUrl, ".png");
        _this.updata(file);
      });
    },
    handleReset() {
      console.log(this.$refs.esign);
      this.$refs.esign.reset();
    },
    //点击确定
    handleGenerate() {
      this.$refs.esign
        .generate()
        .then(res => {
          console.log(res);
          this.baseData = res;
          this.submit();
        })
        .catch(err => {
          //   this.$toast("请签名后再生成签字图片");
        });
    },
    submit() {
      this.toast = this.$toast({
        duration: 0,
        forbidClick: true,
        loadingType: "spinner",
        message: "签名生成中..."
      });

      let _this = this;
      // var canvas = $(".jSignature");
      // var dataURL = canvas.get(0).toDataURL();
      this.rotateBase64Img(this.baseData, 270);
      setTimeout(() => {
        this.imgss = this.baseData;
        _this.toast.message = "签名上传中...";
        console.log(this.baseData);
        // var file = _this.dataURLtoFile(_this.baseData, "img");

        var file = _this.dataURLtoFile(_this.baseData, ".png");

        _this.updata(file);
      }, 300);
    },
    updata(file) {
      let _this = this;
      var formData = new FormData();
      formData.append("file", file);
      userModel.uploadPicturedan(formData).then(e => {
        _this.imgs = e.data;
        _this.toast.clear();
        _this.showDom = false;
        this.shanchuangtupian(e.data);
      });
    },
    //保存合同签名图
    shanchuangtupian(url) {
      userModel.uploadurl({ signUrl: url }).then(e => {
        // _this.$toast("请先完成签名！");
      });
    },
    //				旋转base64
    rotateBase64Img(src, edg) {
      let _this = this;
      var edgData = "";
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      var imgW; //图片宽度
      var imgH; //图片高度
      var size; //canvas初始大小
      if (edg % 90 != 0) {
        console.error("旋转角度必须是90的倍数!");
        throw "旋转角度必须是90的倍数!";
      }
      edg < 0 && (edg = (edg % 360) + 360);
      const quadrant = (edg / 90) % 4; //旋转象限
      const cutCoor = { sx: 0, sy: 0, ex: 0, ey: 0 }; //裁剪坐标
      var image = new Image();
      image.crossOrigin = "anonymous";
      image.src = src;
      image.onload = function() {
        imgW = image.width;
        imgH = image.height;
        size = imgW > imgH ? imgW : imgH;
        canvas.width = size * 2;
        canvas.height = size * 2;
        switch (quadrant) {
          case 0:
            cutCoor.sx = size;
            cutCoor.sy = size;
            cutCoor.ex = size + imgW;
            cutCoor.ey = size + imgH;
            break;
          case 1:
            cutCoor.sx = size - imgH;
            cutCoor.sy = size;
            cutCoor.ex = size;
            cutCoor.ey = size + imgW;
            break;
          case 2:
            cutCoor.sx = size - imgW;
            cutCoor.sy = size - imgH;
            cutCoor.ex = size;
            cutCoor.ey = size;
            break;
          case 3:
            cutCoor.sx = size;
            cutCoor.sy = size - imgW;
            cutCoor.ex = size + imgH;
            cutCoor.ey = size + imgW;
            break;
        }
        ctx.translate(size, size);
        ctx.rotate((edg * Math.PI) / 180);
        ctx.drawImage(image, 0, 0);
        var imgData = ctx.getImageData(
          cutCoor.sx,
          cutCoor.sy,
          cutCoor.ex,
          cutCoor.ey
        );
        if (quadrant % 2 == 0) {
          canvas.width = imgW;
          canvas.height = imgH;
        } else {
          canvas.width = imgH;
          canvas.height = imgW;
        }
        ctx.putImageData(imgData, 0, 0);
        _this.baseData = canvas.toDataURL();
      };
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime
      });
    },
    // ---------------
    qiehuan(row) {
      this.active = row;
      this.onLoad();
    },
    zhifu() {
      var _this = this;
      if (this.imgs == null) {
        _this.$toast("请先完成签名！");
        return;
      }
      var province = this.shangjia.province
        ? this.shangjia.province.toString()
        : "";
      var city = this.shangjia.city ? this.shangjia.city.toString() : "";
      var district = this.shangjia.district
        ? this.shangjia.district.toString()
        : "";
      var address = this.shangjia.address
        ? this.shangjia.address.toString()
        : "";
      var lis = {
        id: 43,
        activityPrice: 1980,
        separateId:1,//消费合伙人传1,88礼包穿2，399套餐传3，999套餐传4
        // activityPrice: 0.01,
        customerId: this.userInfo ? this.userInfo.customerId : "",
        gid: this.shangjia.id,
        payType: 1,
        type: 12,
        // shareId: this.shareId ? this.shareId : null,
        name: "1980元合伙人套餐",
        areaDetail:province +city +district +address, //维修厂详细地址
        carModel: this.chexin ? this.chexin.carBrand : "", //车型
        carNo: this.chexin ? this.chexin.carNo : "", //车牌
        garageName: this.shangjia.name, //修理厂名称
        positionJd: this.shangjia.positionJd,
        positionWd: this.shangjia.positionWd,
        tel: this.shangjia.legalPersonPhone,
        receiver: this.shangjia.legalPerson //用户名称
      };
      var isWxMini = window.__wxjs_environment === "miniprogram";
      console.log(isWxMini);
      if (isWxMini) {
        lis.token = localStorage.getItem("Authorization");
        lis.ccisok = 4; //3:会员卡订单 4:套餐订单 5:充值卡订单
        let payDataStr = JSON.stringify(lis);
        wx.miniProgram.navigateTo({
          url: "/pages/pay/pay?payDataStr=" + payDataStr // 微信小程序调用支付的页面
        });
      } else {
        orderModel.goumaivip(lis).then(res => {
          if (res.code == 200) {
            if (typeof WeixinJSBridge === "undefined") {
              // 微信浏览器内置对象。参考微信官方文档
              if (document.addEventListener) {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  _this.onBridgeReady(res.data),
                  false
                );
              } else if (document.attachEvent) {
                document.attachEvent(
                  "WeixinJSBridgeReady",
                  _this.onBridgeReady(res.data)
                );
                document.attachEvent(
                  "onWeixinJSBridgeReady",
                  _this.onBridgeReady(res.data)
                );
              }
            } else {
              console.log("准备调用微信支付");
              _this.onBridgeReady(res.data);
            }
          }
        });
      }
    },
    onBridgeReady(data) {
      console.log("调用微信支付WeixinJSBridge");
      var vm = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          // 下面参数内容都是后台返回的
          appId: data.appId, // 公众号名称，由商户传入
          timeStamp: data.timeStamp, // 时间戳
          nonceStr: data.nonceStr, // 随机串
          package: data.package, // 预支付id
          signType: data.signType, // 微信签名方式
          paySign: data.paySign // 微信签名
        },
        function(res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            vm.$toast.success("支付成功");
            setTimeout(() => {
              window.location.href =
                "/order_details?id=" +
                data.id +
                "&status=" +
                3 +
                "&type=" +
                10 +
                "&rowtype=" +
                0 +
                "&isok=" +
                true;
              // vm.$router.go(-1);
            }, 1000);
          } else {
            vm.$toast("支付失败");
          }
        }
      );
    },
    onLoad() {
      const data = {
        gid: this.shangjia.id,
        status: this.active
      };

      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      orderModel
        .dingdanlist(data)
        .then(e => {
          loading.clear();
          this.list = e.data;
        })
        .catch(() => loading.clear());
    },

    back() {
      this.$router.go(-1);
    }
  },
  created() {
    this.height = window.innerHeight;
    console.log(this.height);
    // this.onLoad();
    // console.log(utils.getUrlKey("status"));
  }
};
</script>

<style lang="less" scoped>
.qmimg {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  margin-top: 110px;
  margin-left: 70px;
  z-index: 99;
}
.qmimg2 {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 40px;
  z-index: 99;
  margin-bottom: 98px;
  margin-left: 145px;
}
.order_list {
  margin: 0 10px;
  background: #fff;
  padding: 0 10px;
  border-radius: 5px;
  overflow: hidden;
}
.ordrt_h1 {
  overflow: hidden;
  color: #666;
  font-size: 12px;
  padding-top: 10px;
}
.zhifu {
  // display: inline-block;
  font-size: 12px;
  padding: 0 6px;
  color: #b22222;
  border: 1px solid #b22222;
  border-radius: 12px;
  float: right;
  line-height: 20px;
  height: 22px;
  font-weight: bold;
}
.hed_lis {
  height: 45px;
  background: #fff;
  border-bottom: 1px solid #ebedf0;
  position: fixed;
  top: 46px;
  width: 100%;
  z-index: 99;
}
.hhcc {
  float: left;
  font-size: 14px;
  color: #646566;
  width: 50%;
  text-align: center;
  line-height: 45px;
  position: relative;
  overflow: hidden;
}
.hua {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-bottom: 5px;
  width: 46px;
  text-align: center;
  margin-left: -23px;
  height: 3px;
  z-index: 1;
  background-color: #ee0a24;
  border-radius: 1.5px;
}
.bot {
  width: 100%;
  overflow: hidden;
  padding-bottom: 10px;
}
.imgs {
  width: 100%;
  display: block;
}
.bott {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35px;
  background: #fff;
  overflow: hidden;
  z-index: 999;
}
.but {
  float: left;
  width: 50%;
  height: 35px;
  display: block;
  //   line-height: 35px;
  border-radius: 0;
}
.bot {
  box-shadow: 0 2px 3px 1px rgba(212, 0, 0, 0.47);
  border-radius: 20px;
  height: 40px;
  width: 89%;
  margin: auto;
  margin-top: 5px;
}
.tes {
  display: inline-block;
  line-height: 40px;
  // margin-top: 8px;
  font-family: PingFangSC-Medium;
  font-size: 18px;
  letter-spacing: 0;
}
// ------------------------------
.qianming {
  height: 100%;
  width: 100%;
  z-index: 99;
}
</style>
<style lang="less">
.van-tabs__line {
  bottom: 20px;
}
</style>
